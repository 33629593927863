//
// Captioning
// --------------------------------------------------


// Base styles
// -------------------------

.image-box {
    
    display:inline-block;
    position:relative;
}
 .image-box-text {
    position: absolute;
    background-color: rgba(15, 15, 15, 0.93);
    color: #fff;
    bottom: 0px;
    text-align: left;
    padding: 20px 15px 20px 15px;
    width:100%;
}
.image-box-title {
    position: absolute;
    bottom: 80px;
    text-align: left;
    z-index: 15;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
    padding-left: 20px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    background-color: rgba(194, 0, 0, 0.93);
    width: 100%;
}
 .image-box-text-below {
    position: relative;
    background-color: rgba(15, 15, 15, 0.93);
    color: #fff;
    text-align: left;
    padding: 20px 15px 20px 15px;
    width:100%;
}
.image-box-title-below {
    position: relative;
    text-align: left;
    z-index: 15;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
    padding-left: 20px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    background-color: rgba(194, 0, 0, 0.93);
    width: 100%;
}
// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border) {
  color: @color !important;
  background-color: @background !important;
  //border-color: @border !important;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color !important;
    background-color: darken(@background, 10%) !important;
        //border-color: darken(@border, 12%) !important;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @background;
          //border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

.button-variant-flames(@color; @background; @border) {
  color: @color !important;
  background-color: @background !important;
  background-image: url("/file_source/corporate_web/Design/themes/default/images/flames-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  //border-color: @border !important;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color !important;
    background-color: darken(@background, 10%) !important;
  background-image: url("/file_source/corporate_web/Design/themes/default/images/flames-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
        //border-color: darken(@border, 12%) !important;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @background;
  background-image: url("/file_source/corporate_web/Design/themes/default/images/flames-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
          //border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

.button-gradient(@color, @background, @btn-text-shadow-dbo, @btn-text-shadow-color) {
	color: @color;
	#gradient > .vertical(lighten(@background, 5%), darken(@background, 5%), 0%, 100%);
	//border-color: darken(@background, 5%);
	/*border-bottom-color: darken(@background, 20%);*/
	text-shadow: @btn-text-shadow-dbo @btn-text-shadow-color;
	.box-shadow(inset 0 1px 0 rgba(255, 255, 255, .1));
		&:hover,
		&:focus,
		&:active,
		&.active {
	#gradient > .vertical(darken(@background, 5%), lighten(@background, 5%), 0%, 100%);
	//border-color: darken(@background, 10%) !important;
	/*border-bottom-color: darken(@background, 20%);*/
	color: @color !important;
}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: @background !important;
			//border-color: darken(@background, 5%) !important;
			color: @color !important;
		}
	}
} 

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}
